import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import Layout from "../../components/winter-2022/Layout"
import Thanks from "../../components/winter-2022/Thanks"
import "../../styles/Global.css"
import "../../styles/layout.module.css"

const ThanksPage = () => {

    useEffect(() => {
        window.dataLayer.push({
            brand: "Corona",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Isla Corona Success",
            pageType: "Conversion Success",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.cervezacorona.com.ar/islacorona/success",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        })
    }, [])


    return (
        <Layout type="secundary">
            <SEO title="ISLA CORONA 2022" />
            <Thanks />
        </Layout>
    )
}

export default ThanksPage
